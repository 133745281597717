<template>
  <v-container
    id="recipe"
    fluid
    tag="section"
    class="custom-plan container-full-size"
  >
    <template>
      <v-col
        cols="12"
        style="display: flex; justify-content: space-between;"
      >
        <div>
          <v-btn
            color="default"
            class="mr-0"
            @click="back()"
          >
            {{ str['back'] }}
          </v-btn>
          <v-btn
            v-if="recipesList && recipesList.length > 1"
            color="blue darken-3"
            class="mr-0"
            style="margin-left: 10px;"
            @click="previousRecipe()"
          >
            {{ str['previous_back'] }}
          </v-btn>
          <v-btn
           v-if="recipesList && recipesList.length > 1"
            color="blue darken-3"
            class="mr-0"
            style="margin-left: 10px;"
            @click="nextRecipe()"
          >
            {{ str['next'] }}
          </v-btn>
        </div>
        <div v-if="editPermission">
          <v-btn
            v-if="recipe.id"
            color="error"
            style="margin-right: 10px;"
            @click="deleteRecipe()"
          >
            {{ str['delete'] }}
          </v-btn>
          <v-btn
            color="success"
            class="mr-0"
            @click="saveRecipe()"
          >
            {{ str['save'] }}
          </v-btn>
        </div>
      </v-col>
      <v-col
        cols="12"
      >
        <v-card
          class="data-container-content"
        >
          <v-col
            cols="12"
            class="data-container-title"
          >
            <v-row>
              <v-col
                :cols="parentCategories && parentCategories.length && categories && categories.length ? (hasAppAvailabilityControl ? 3 : 4) : 12"
                :class="{'mb-4': ((!parentCategories || (parentCategories && !parentCategories.length)) && (!parentCategories || (parentCategories && !parentCategories.length)))}"
              >
                <v-text-field
                  v-bind:style="{'pointer-events': editPermission ? '' : 'none'}"
                  v-model="recipe.name"
                  :label="str['recipe']"
                  class="purple-input"
                  style="margin: 0;"
                  hide-details
                />
                <v-text-field
                  v-if="hasTranslations && hasTranslations.indexOf('en') > -1"
                  v-bind:style="{'pointer-events': editPermission ? '' : 'none'}"
                  v-model="recipe.name_en"
                  :label="str['recipe'] + ' (EN)'"
                  class="purple-input"
                  style="margin: 0;"
                  hide-details
                />
                <v-text-field
                  v-if="hasTranslations && hasTranslations.indexOf('es') > -1"
                  v-bind:style="{'pointer-events': editPermission ? '' : 'none'}"
                  v-model="recipe.name_es"
                  :label="str['recipe'] + ' (ES)'"
                  class="purple-input"
                  style="margin: 0;"
                  hide-details
                />
                <v-text-field
                  v-if="hasTranslations && hasTranslations.indexOf('fr') > -1"
                  v-bind:style="{'pointer-events': editPermission ? '' : 'none'}"
                  v-model="recipe.name_fr"
                  :label="str['recipe'] + ' (FR)'"
                  class="purple-input"
                  style="margin: 0;"
                  hide-details
                />
              </v-col>
              <v-col
                v-if="parentCategories && parentCategories.length"
                :cols="hasAppAvailabilityControl ? 3 : 4"
              >
                <v-autocomplete
                  v-bind:style="{'pointer-events': editPermission ? '' : 'none'}"
                  v-model="recipe.parent_category"
                  :label="str['filter']"
                  item-text="label"
                  item-value="value"
                  :items="parentCategories"
                  :no-data-text="str['no_data']"
                  hide-details
                />
              </v-col>
              <v-col
                v-if="categories && categories.length"
                :cols="hasAppAvailabilityControl ? 3 : 4"
              >
                <v-autocomplete
                  v-bind:style="{'pointer-events': editPermission ? '' : 'none'}"
                  v-model="recipe.category"
                  :label="str['category']"
                  item-text="label"
                  item-value="value"
                  :items="categories"
                  :no-data-text="str['no_data']"
                  hide-details
                />
              </v-col>
              <v-col
                v-if="hasAppAvailabilityControl"
                cols="3"
              >
                <v-checkbox
                  v-model="recipe.app_show"
                  :label="str['app_show']"
                  class="form-field-checkbox"
                  hide-details
                />
              </v-col>
            </v-row>
            <v-row
              v-if="hasImage"
              class="mb-1"
            >
              <v-col
                cols="6"
                class="pt-0"
                style="width: 225px; max-width: 225px;"
              >
                <div class="input-image-field">
                  <h4>
                    {{ str['image'] }}
                  </h4>
                  <div class="cursor-hover">
                    <v-img
                      v-if="recipe.image && recipe.image.base64"
                      :src="recipe.image.base64"
                      style="width: 100%; height: 100%;"
                      contain
                    />
                    <v-icon
                      v-if="!recipe.image || (recipe.image && !recipe.image.base64)"
                      color="success"
                      size="40"
                    >
                      mdi-plus-circle
                    </v-icon>
                    <input
                      id="recipe-input-image"
                      type="file"
                      @change="updateImage"
                      accept=".png,.jpg,.jpeg"
                    >
                  </div>
                  <v-icon
                    v-if="recipe.image && recipe.image.base64"
                    color="error"
                    size="30"
                    @click="updateImage(null)"
                    class="cursor-hover input-image-field-remove"
                  >
                    mdi-close-circle
                  </v-icon>
                </div>
              </v-col>
              <v-col
                :cols="12"
                :lg="6"
                :md="6"
                :sm="6"
                style="min-width: calc(100% - 225px);"
              >
                <v-textarea
                  v-bind:style="{'pointer-events': editPermission ? '' : 'none'}"
                  v-model="recipe.description"
                  :label="str['remarks']"
                  :rows="hasTranslations ? 3 : 5"
                  class="pt-0 mt-0"
                />
                <v-textarea
                  v-if="hasTranslations && hasTranslations.indexOf('en') > -1"
                  v-bind:style="{'pointer-events': editPermission ? '' : 'none'}"
                  v-model="recipe.description_en"
                  :label="str['remarks'] + ' (EN)'"
                  rows="3"
                  class="pt-0 mt-0"
                />
                <v-textarea
                  v-if="hasTranslations && hasTranslations.indexOf('es') > -1"
                  v-bind:style="{'pointer-events': editPermission ? '' : 'none'}"
                  v-model="recipe.description_es"
                  :label="str['remarks'] + ' (ES)'"
                  rows="3"
                  class="pt-0 mt-0"
                />
                <v-textarea
                  v-if="hasTranslations && hasTranslations.indexOf('fr') > -1"
                  v-bind:style="{'pointer-events': editPermission ? '' : 'none'}"
                  v-model="recipe.description_fr"
                  :label="str['remarks'] + ' (FR)'"
                  rows="3"
                  class="pt-0 mt-0"
                />
              </v-col>
            </v-row>
            <div class="text-right">
              <v-btn
                v-if="editPermission"
                color="success"
                class="mr-0"
                @click="newFood()"
              >
                {{ str['add_food'] }}
              </v-btn>
              <v-btn
                v-if="showSupplements && editPermission"
                color="blue"
                class="mr-0"
                style="margin-left: 10px;"
                @click="openAddSupplementDialog()"
              >
                {{ str['add_supplement'] }}
              </v-btn>
            </div>
          </v-col>
          <v-col
            cols="12"
          >
            <div>
              <div class="day-inputs-title">
                <b>{{ str['macros'] }}</b>
              </div>
              <div class="day-inputs">
                <div
                  v-for="(valueItem, index) in valuesItems"
                  :key="index"
                >
                  <div>
                    {{ valueItem.title }}
                  </div>
                  <div v-if="recipe[valueItem.currentKey]">
                    {{ Math.round(recipe[valueItem.currentKey] * 100) / 100 }}
                  </div>
                  <div v-if="!recipe[valueItem.currentKey]">
                    ...
                  </div>
                </div>
              </div>
            </div>
          </v-col>
          <div style="display: flex;">
            <v-col
              v-if="recipe.foods"
              :cols="showSupplements ? 6 : 12"
              class="data-container-list"
            >
              <div>
                <h4 style="margin-bottom: 10px;">
                  {{ str['foods'] }}
                </h4>
              </div>
              <div
                v-if="!recipe.foods.length"
              >
                {{ str['foods_empty'] }}
              </div>
              <draggable
                :list="recipe.foods"
                class="sortable-list-group"
                handle=".handle"
                @start="dragging = true"
                @end="dragging = false"
              >
                <div
                  v-for="(food, index) in recipe.foods"
                  v-show="!food.new"
                  :key="index"
                  class="sortable-list-group-item-box"
                >
                  <div @click="openFood(index)">
                    <div
                      v-if="foodData[food.food_id]"
                    >
                      <b>{{ food.value }}{{ foodTypeUnit[foodData[food.food_id].type].unit }} {{ foodData[food.food_id].name }}</b>
                    </div>
                    <div>
                      {{ str['calories'] }}: {{ food.calories }} kCal | {{ str['protein'] }}: {{ food.protein }} g | 
                      {{ str['carbohydrates'] }}: {{ food.carbs }} g | {{ str['fat'] }}: {{ food.fat }} g
                    </div>
                    <div
                      v-if="showDetails"
                    >
                      {{ food.details }}
                    </div>
                  </div>
                  <div v-if="editPermission">
                    <v-icon
                      color="error"
                      style="margin-right: 10px;"
                      @click="deleteFood(index)"
                    >
                      mdi-delete
                    </v-icon>
                    <v-icon
                      color="default"
                      class="handle"
                    >
                      mdi-format-align-justify
                    </v-icon>
                  </div>
                </div>
              </draggable>
            </v-col>
            <v-col
              v-if="showSupplements && recipe.extra_data && recipe.extra_data.supplements && recipe.extra_data.supplements.length"
              cols="6"
              class="data-container-list"
            >
              <div>
                <h4 style="margin-bottom: 10px;">
                  {{ str['supplements'] }}
                </h4>
              </div>
              <div
                v-for="(supplement, index) in recipe.extra_data.supplements"
                :key="index"
                class="sortable-list-group-item-box"
              >
                <div>
                  <div
                    v-if="supplementData[supplement.id]"
                  >
                    <b>{{ supplement.value }} {{ supplementData[supplement.id].name }}</b>
                  </div>
                </div>
                <div v-if="editPermission">
                  <v-icon
                    color="error"
                    class="mr-0"
                    @click="deleteSupplement(index)"
                  >
                    mdi-delete
                  </v-icon>
                </div>
              </div>
            </v-col>
          </div>

          <v-dialog
            v-if="food"
            v-model="dialogFood"
            persistent
            max-width="600px"
          >
            <v-card>
              <v-card-title>
                <span class="headline">
                  {{ str['add_food'] }}
                </span>
              </v-card-title>
              <v-card-text style="padding-top: 10px; padding-bottom: 10px;">
                <v-container style="padding-top: 0; padding-bottom: 0;">
                  <v-row>
                    <v-col
                      cols="12"
                    >
                      <v-autocomplete
                        v-model="food.food_id"
                        :label="str['food']"
                        item-text="name"
                        item-value="id"
                        :items="foodsItems"
                        :no-data-text="str['no_data']"
                      />
                      <v-text-field
                        v-if="foodData[food.food_id] && foodTypeUnit[foodData[food.food_id].type].value === 1"
                        v-model="food.value"
                        :label="foodTypeUnit[foodData[food.food_id].type].label"
                        required
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                      />
                      <v-text-field
                        v-if="foodData[food.food_id] && foodTypeUnit[foodData[food.food_id].type].value !== 1"
                        v-model="food.value"
                        :label="foodTypeUnit[foodData[food.food_id].type].label"
                        required
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                      />
                      <v-text-field
                        v-if="!foodData[food.food_id]"
                        v-model="food.value"
                        :label="str['value']"
                        required
                      />
                      <v-textarea
                        v-if="showDetails"
                        v-model="food.details"
                        :label="str['remarks']"
                        hide-details
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions style="padding-top: 0;">
                <v-spacer></v-spacer>
                <v-btn
                  color="default"
                  @click="cancelFood"
                >
                  {{ str['cancel'] }}
                </v-btn>
                <v-btn
                  :disabled="!food.food_id"
                  color="success"
                  @click="saveFood"
                >
                  {{ str['save'] }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog
            v-if="showSupplements"
            v-model="dialogAddSupplement"
            persistent
            max-width="600px"
          >
            <v-card>
              <v-card-title>
                <span class="headline">
                  {{ str['add_supplement'] }}
                </span>
              </v-card-title>
              <v-card-text style="padding-top: 10px; padding-bottom: 10px;">
                <v-container style="padding-top: 0; padding-bottom: 0;">
                  <v-row>
                    <v-col
                      cols="12"
                      style="min-height: 300px"
                    >
                      <v-autocomplete
                        v-model="supplementToAdd.id"
                        :label="str['supplement']"
                        item-text="name"
                        item-value="id"
                        :items="supplementsItems"
                        :no-data-text="str['no_data']"
                      />
                      <v-text-field
                        v-model="supplementToAdd.value"
                        :label="str['value']"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions style="padding-top: 0;">
                <v-spacer></v-spacer>
                <v-btn
                  color="default"
                  @click="closeAddSupplementDialog"
                >
                  {{ str['cancel'] }}
                </v-btn>
                <v-btn
                  color="success"
                  @click="addSupplement"
                >
                  {{ str['save'] }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
      </v-col>
    </template>
  </v-container>
</template>

<script>
  import Api from '@/services/Api'
  import Utils from '@/services/Utils'
  import draggable from 'vuedraggable'

  export default {
    components: {
      draggable,
    },
    data () {
      const user = Utils.getUser()
      const editPermission = Utils.hasPermission('receipt_list_edit')
      const cacheRecipe = Utils.getStorage('recipe')
      const recipe = cacheRecipe ? cacheRecipe : {
        name: window.strings['recipe'],
        description: '',
        category: '',
        parent_category: '',
        foods: [],
        extra_data: '',
      }

      let showDetails = false
      if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.show_food_details) {
        showDetails = true
      }

      let showSupplements = false
      if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.supplements) {
        showSupplements = true
      }

      let showDesserts = false
      if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.show_desserts) {
        showDesserts = true
      }

      let showSauces = false
      if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.show_sauces) {
        showSauces = true
      }

      let hasTranslations = false
      if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.has_translations) {
        hasTranslations = user.configurations.food_plans.has_translations
      }

      let hasImage = false
      if (user && user.configurations && user.configurations.recipe && user.configurations.recipe.has_image) {
        hasImage = true
      }

      let hasAppAvailabilityControl = false
      if (user && user.configurations && user.configurations.recipe && user.configurations.recipe.has_app_availability_control) {
        hasAppAvailabilityControl = true
      }

      return {
        str: window.strings,
        recipesList: window.recipesList,
        user: user,
        editPermission: editPermission,
        recipe: recipe,
        showDetails: showDetails,
        showSupplements: showSupplements,
        showDesserts: showDesserts,
        showSauces: showSauces,
        hasTranslations: hasTranslations,
        hasImage: hasImage,
        hasAppAvailabilityControl: hasAppAvailabilityControl,
        dragging: false,
        foodIndex: null,
        food: null,
        dialogFood: null,
        foodsItems: [],
        foodData: {},
        foodTypeUnit: {},
        supplementType: 100,
        supplementsItems: [],
        dialogAddSupplement: false,
        supplementData: {},
        supplementToAdd: {},
        valuesItems: [],
        categories: Utils.getRecipeCategories(),
        parentCategories: Utils.getRecipeParentCategories(),
        imageMaxSize: 600,
      }
    },
    beforeMount: function () {
      if (!this.user) {
        return false
      }
      this.$isLoading(true)
      this.getFoodFields()
      this.getFoodsItems()
      this.getFoodTypesUnits()
      this.getImage()
      if (this.showSupplements) {
        this.getSupplements()
        this.refreshSupplements()
      }
    },
    methods: {
      back: function () {
        Utils.removeStorage('recipe')
        this.$router.goBack()
      },
      getFoodFields: function () {
        const fields = [{
          title: window.strings['calories_kcal'],
          currentKey: 'calories',
          goalKey: 'calories_goal',
          hidden: true,
        }, {
          title: window.strings['protein_g'],
          currentKey: 'protein',
          goalKey: 'protein_goal',
        }, {
          title: window.strings['carbohydrates_g'],
          currentKey: 'carbs',
          goalKey: 'carbs_goal',
        }, {
          title: window.strings['fat_g'],
          currentKey: 'fat',
          goalKey: 'fat_goal',
        }]

        if (this.user && this.user.configurations && this.user.configurations.food_plans && this.user.configurations.food_plans.show_sub_macros) {
          fields.push({
            title: window.strings['fiber_g'],
            currentKey: 'fiber',
            goalKey: 'fiber_goal',
          })
          fields.push({
            title: window.strings['sodium_g'],
            currentKey: 'sodium',
            goalKey: 'sodium_goal',
          })
          fields.push({
            title: window.strings['sugar_g'],
            currentKey: 'sugar',
            goalKey: 'sugar_goal',
          })
        }

        this.valuesItems = fields
      },
      getFoodsItems: function () {
        const self = this
        Api.getFoods({
          useCache: true,
        }, function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.foodsItems = response.data
            self.foodData = {}
            for (let i = 0; i < response.data.length; i++) {
              self.foodData[response.data[i].id] = response.data[i]
            }
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      getFoodTypesUnits: function () {
        const foodTypes = Utils.getFoodTypes()
        this.foodTypeUnit = {}
        for (let i = 0; i < foodTypes.length; i++) {
          this.foodTypeUnit[foodTypes[i].value] = foodTypes[i]
        }
      },
      getImage: function () {
        const self = this
        if (this.recipe.image) {
          Api.getImage(this.recipe.image, function (response) {
            if (response.success) {
              self.$set(self.recipe, 'image', {
                id: response.data[0].id,
                base64: response.data[0].file
              })
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }
      },
      getSupplements: function () {
        const self = this
        Api.getContents({
          type: this.supplementType,
          useCache: true,
        }, function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.supplementsItems = response.data
            self.supplementData = {}
            for (let i = 0; i < response.data.length; i++) {
              self.supplementData[response.data[i].id] = response.data[i]
            }
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      refreshSupplements: function () {
        if (this.showSupplements) {
          if (this.recipe.extra_data) {
            if (typeof this.recipe.extra_data === 'string') {
              this.recipe.extra_data = JSON.parse(this.recipe.extra_data)
            }
          } else {
            this.recipe.extra_data = {}
          }
  
          if (!this.recipe.extra_data.supplements ||
            (this.recipe.extra_data.supplements && this.recipe.extra_data.supplements[0] &&
              !this.recipe.extra_data.supplements[0].id)
          ) {
            this.recipe.extra_data.supplements = []
          }
        }
      },
      saveRecipe (callback) {
        const self = this
        this.$isLoading(true)
        this.getRecipeToSave(function (data) {
          if (data.id) {
            Api.updateRecipe(data, function (response) {
              self.$isLoading(false)
              if (response.success) {
                if (callback) {
                  callback()
                } else {
                  self.back()
                }
              } else {
                self.$alert(
                  response.message,
                  '',
                  'warning',
                  Utils.getAlertOptions()
                )
              }
            })
          } else {
            Api.addRecipe(data, function (response) {
              self.$isLoading(false)
              if (response.success) {
                if (callback) {
                  callback()
                } else {
                  self.back()
                }
              } else {
                self.$alert(
                  response.message,
                  '',
                  'warning',
                  Utils.getAlertOptions()
                )
              }
            })
          }
        })
      },
      getRecipeToSave (callback) {
        const recipe = JSON.parse(JSON.stringify(this.recipe))
        if (recipe.name) {
          recipe.name = recipe.name.trim()
        }
        if (this.hasTranslations) {
          this.hasTranslations.forEach(function (t) {
            if (recipe['name_' + t]) {
              recipe['name_' + t] = recipe['name_' + t].trim()
            }
          })
        }
        if (recipe.extra_data && typeof recipe.extra_data !== 'string') {
          recipe.extra_data = JSON.stringify(recipe.extra_data)
        }
        const keys = ['value', 'food_id', 'details']
        for (let f = 0; f < recipe.foods.length; f++) {
          recipe.foods[f].value = parseFloat(recipe.foods[f].value)
          for (const key in recipe.foods[f]) {
            if (keys.indexOf(key) === -1) {
              delete recipe.foods[f][key]
            }
          }
        }
        if (this.showDesserts || this.showSauces) {
          const recipeCategory = this.categories.find(function (cat) {
            return cat.app && cat.value === recipe.category
          })
          if (recipeCategory) {
            recipe.type = recipeCategory.app.value
          }
        }
        if (this.hasAppAvailabilityControl) {
          recipe.app_show = recipe.app_show ? 1 : 0
        }
        if (callback) {
          if (recipe.image && recipe.image.updated) {
            this.saveImage(recipe.image, function (imageId) {
              recipe.image = imageId
              callback(recipe)
            })
          } else {
            if (recipe.image) {
              recipe.image = recipe.image.id
            }
            callback(recipe)
          }
          return true
        }
        delete recipe.image
        return recipe
      },
      saveImage (image, callback) {
        const self = this
        if (!image || (image && !image.base64)) {
          return callback(null)
        }
        const payload = {
          id: image.id,
          name: 'image_recipe_' + (new Date()).getTime(),
          type: 1,
          file: image.base64
        }
        if (payload.id) {
          Api.updateImage(payload, function (response) {
            if (response.success) {
              callback(response.data.id)
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        } else {
          Api.newImage(payload, function (response) {
            if (response.success) {
              callback(response.data.id)
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }
      },
      deleteRecipe () {
        const self = this
        const id = this.recipe.id
        this.$confirm(
          window.strings['want_delete_recipe'] + (this.recipe ? (' "' + this.recipe.name + '"') : '') + '?',
          '',
          'warning',
          Utils.getAlertOptions(true, true)
        ).then(() => {
          self.$isLoading(true)
          Api.deleteRecipe(id, function (response) {
            self.$isLoading(false)
            if (response.success) {
              self.back()
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }).catch(() => { })
      },
      newFood: function () {
        this.openFood(null)
      },
      openFood: function (index) {
        if (this.editPermission) {
          this.foodIndex = index
          if (this.foodIndex === null) {
            this.food = {
              value: 0,
              food_id: null,
              details: '',
            }
          } else {
            this.food = {
              value: this.recipe.foods[index].value,
              food_id: this.recipe.foods[index].food_id,
              details: this.recipe.foods[index].details,
            }
          }
          this.dialogFood = true
        }
      },
      cancelFood: function () {
        this.foodIndex = null
        this.food = null
        this.dialogFood = false
      },
      saveFood: function () {
        if (this.foodIndex === null) {
          this.recipe.foods.push({
            food_id: this.food.food_id,
            value: this.food.value,
            details: this.food.details,
          })
        } else {
          this.recipe.foods[this.foodIndex].value = this.food.value
          this.recipe.foods[this.foodIndex].food_id = this.food.food_id
          this.recipe.foods[this.foodIndex].details = this.food.details
        }
        this.foodIndex = null
        this.food = null
        this.dialogFood = false
        this.refreshMacros()
      },
      deleteFood: function (index) {
        this.recipe.foods.splice(index, 1)
        this.refreshMacros()
      },
      openAddSupplementDialog: function () {
        const defaultSupplement = this.supplementsItems && this.supplementsItems[0] ?
          this.supplementsItems[0] : null

        this.supplementToAdd = {
          id: defaultSupplement ? defaultSupplement.id : '',
          value: ''
        }
        this.dialogAddSupplement = true
      },
      closeAddSupplementDialog: function () {
        this.dialogAddSupplement = false
      },
      addSupplement: function () {
        if (!this.recipe.extra_data.supplements) {
          this.recipe.extra_data.supplements = []
        }
        this.recipe.extra_data.supplements.push(JSON.parse(JSON.stringify(this.supplementToAdd)))
        this.dialogAddSupplement = false
      },
      deleteSupplement: function (index) {
        this.recipe.extra_data.supplements.splice(index, 1)
      },
      refreshMacros: function () {
        const self = this
        const payload = {
          days: [{
            meals: [this.getRecipeToSave()]
          }]
        }
        Api.calculateFoodPlan(payload, function (response) {
          if (response.success) {
            const recipeClone = JSON.parse(JSON.stringify(self.recipe))
            self.recipe = response.data.days[0].meals[0]
            self.recipe.image = recipeClone.image
            self.refreshSupplements()
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      updateImage (event) {
        const self = this
        this.$isLoading(true)

        if (event) {
          const file = event.srcElement.files[0]
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = function (e) {
            const image = new Image()
            image.src = reader.result
            image.onload = function () {
              if (image.width < self.imageMaxSize && image.height < self.imageMaxSize) {
                setImage(e.target.result)
              } else {
                setImage(resizeImage(image))
              }
            }
          }
          reader.onerror = function () {
            self.$isLoading(false)
            self.$alert(
              window.strings['common_error'],
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        } else {
          setImage(null)
        }

        function resizeImage (image) {
          const canvas = document.createElement('canvas')
          let width = image.width
          let height = image.height

          if (width > height) {
            if (width > self.imageMaxSize) {
              height *= self.imageMaxSize / width
              width = self.imageMaxSize
            }
          } else {
            if (height > self.imageMaxSize) {
              width *= self.imageMaxSize / height
              height = self.imageMaxSize
            }
          }
          canvas.width = width
          canvas.height = height
          const ctx = canvas.getContext('2d')
          ctx.drawImage(image, 0, 0, width, height)
          return canvas.toDataURL()
        }

        function setImage (image) {
          self.$set(self.recipe, 'image', {
            id: image && self.recipe.image && self.recipe.image.id ? self.recipe.image.id : null,
            base64: image,
            updated: true
          })
          self.$forceUpdate()
          document.getElementById('recipe-input-image').value = ''
          self.$isLoading(false)
        }
      },
      previousRecipe () {
        const self = this
        const currentIndex = this.recipesList.findIndex(function (i) {
          return i.id === self.recipe.id
        })
        this.openRecipe(this.recipesList[currentIndex - 1] ? this.recipesList[currentIndex - 1] : this.recipesList[this.recipesList.length - 1])
      },
      nextRecipe () {
        const self = this
        const currentIndex = this.recipesList.findIndex(function (i) {
          return i.id === self.recipe.id
        })
        this.openRecipe(this.recipesList[currentIndex + 1] ? this.recipesList[currentIndex + 1] : this.recipesList[0])
      },
      openRecipe (item) {
        const self = this
        if (item) {
          this.saveRecipe(function () {
            Utils.setStorage('recipe', item)
            self.recipe = item
            self.getImage()
            if (self.showSupplements) {
              self.refreshSupplements()
            }
          })
        }
      },
    },
  }
</script>
